// MonthlySchedule.js
import React, { useState } from "react";
import { Button, Row, Col, Card, Badge } from "react-bootstrap";
import DatePicker from "react-datepicker";
import DraggableBadge from "./DraggableBadge";
import ShiftDroppable from "./ShiftDroppable";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
  DragOverlay,
} from "@dnd-kit/core";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, ENDPOINTS } from "./config";
import "./MonthlySchedule.css"; // Ensure correct path

const MonthlySchedule = ({
  doctors,
  shifts,
  selectedMonth,
  setSelectedMonth,
  calculateSchedule,
  setSelectedDate,
  setShifts, // To update shifts state
}) => {
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const [activeId, setActiveId] = useState(null);
  const [draggedDoctor, setDraggedDoctor] = useState(null);

  // Initialize sensors
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  // Group shifts by date and shift_type
  const groupedShifts = shifts.reduce((acc, shift) => {
    const { date, shift_type } = shift;
    if (!acc[date]) acc[date] = {};
    if (!acc[date][shift_type]) acc[date][shift_type] = [];
    acc[date][shift_type].push(shift);
    return acc;
  }, {});

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
    const shift = shifts.find((s) => s.id === parseInt(event.active.id));
    if (shift && shift.doctor_id) {
      const doctor = doctors.find((doc) => doc.id === shift.doctor_id);
      setDraggedDoctor(doctor);
    }
  };

  const handleDragEnd = (event) => {
  const { active, over } = event;

  if (!over) {
    setActiveId(null);
    setDraggedDoctor(null);
    return;
  }

  if (active.id !== over.id) {
    const sourceShiftId = parseInt(active.id); // Shift ID of the dragged doctor
    const targetGroupId = over.id; // e.g., 'day-2024-10-25' or 'night-2024-10-25'

    // Parse targetGroupId to get shift_type and dateStr
    const [shiftType, ...dateParts] = targetGroupId.split('-');
    const dateStr = dateParts.join('-'); // Handles dates with hyphens

    if (!shiftType || !dateStr) {
      toast.error("Invalid Shift assignment");
      setActiveId(null);
      setDraggedDoctor(null);
      return;
    }

    // Find source shift
    const sourceShift = shifts.find((shift) => shift.id === sourceShiftId);
    if (!sourceShift) {
      toast.error("Invalid Shift assignment");
      setActiveId(null);
      setDraggedDoctor(null);
      return;
    }

    const doctorId = sourceShift.doctor_id;
    const doctorName = sourceShift.doctor_name;

    if (!doctorId) {
      toast.error("No doctor assigned to the source shift.");
      setActiveId(null);
      setDraggedDoctor(null);
      return;
    }

    // Check if the doctor is already assigned to another shift on the same day
    const conflictingShift = shifts.find(
      (shift) =>
        shift.doctor_id === doctorId &&
        shift.date === dateStr &&
        shift.id !== sourceShiftId
    );

    if (conflictingShift) {
      toast.error(
        `Doctor is already assigned to shift ID ${conflictingShift.id} on ${conflictingShift.date}`
      );
      setActiveId(null);
      setDraggedDoctor(null);
      return;
    }

    // Update the shifts state: append doctor to target shift, unassign from source shift
    const updatedShifts = shifts.map((shift) => {
      if (shift.shift_type === shiftType && shift.date === dateStr) {
        return {
          ...shift,
          doctor_id: shift.doctor_id ? `${shift.doctor_id},${doctorId}` : `${doctorId}`,
          doctor_name: shift.doctor_name ? `${shift.doctor_name}, ${doctorName}` : `${doctorName}`,
        };
      }
      if (shift.id === sourceShiftId) {
        return {
          ...shift,
          doctor_id: null,
          doctor_name: "Unassigned",
        };
      }
      return shift;
    });

    setShifts(updatedShifts);
  }

  setActiveId(null);
  setDraggedDoctor(null);
};

  const handleDragCancel = () => {
    setActiveId(null);
    setDraggedDoctor(null);
  };

  const handleSaveSchedule = () => {
    // Prepare the schedule data to send to the backend
    const scheduleData = shifts.map((shift) => ({
      id: shift.id,
      doctor_id: shift.doctor_id,
    }));

    axios
      .put(`${API_URL}${ENDPOINTS.SHIFTS}/bulk_update`, {
        shifts: scheduleData,
      })
      .then(() => {
        toast.success("Schedule saved successfully!");
      })
      .catch((error) => {
        console.error("Error saving schedule:", error);
        const errorMessage =
          error.response?.data?.message || "Failed to save schedule.";
        toast.error(errorMessage);
      });
  };

  const renderCalendar = () => {
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDay = new Date(year, month, 1).getDay();

    let calendar = [];

    // Add empty cells for days before the first of the month
    for (let i = 0; i < firstDay; i++) {
      calendar.push(
        <Col key={`empty-${i}`} xs={4} md={2} className="mb-2"></Col>
      );
    }

    // Add cells for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dateStr = date.toISOString().split("T")[0];

      // Check if the date exists in groupedShifts
      const dayShifts = groupedShifts[dateStr] || {};

      calendar.push(
        <Col key={dateStr} xs={4} md={2} className="mb-2">
          <Card
            onClick={() => setSelectedDate(date)}
            className={`text-center ${isWeekend(date) ? "bg-light" : ""}`}
            style={{ cursor: "pointer", minHeight: "150px", position: 'relative' }}
          >
            <Card.Body>
              <Card.Title>{day}</Card.Title>
              <Card.Text>
                <strong>Day:</strong>{" "}
                {dayShifts["day"] ? (
                  <ShiftDroppable
                    shiftId={`day-${dateStr}`} // Unique ID for the day shift group
                    shiftType="day"
                    date={dateStr}
                  >
                    {dayShifts["day"].map((shift) => (
                      shift.doctor_id ? (
                        <DraggableBadge
                          key={shift.id} // Unique key for each badge
                          id={shift.id.toString()} // Unique ID for each shift assignment
                          name={shift.doctor_name}
                          bg="primary"
                        />
                      ) : (
                        <span key={shift.id}>Unassigned</span>
                      )
                    ))}
                  </ShiftDroppable>
                ) : (
                  "No Day Shift"
                )}
                <br />
                <strong>Night:</strong>{" "}
                {dayShifts["night"] ? (
                  <ShiftDroppable
                    shiftId={`night-${dateStr}`} // Unique ID for the night shift group
                    shiftType="night"
                    date={dateStr}
                  >
                    {dayShifts["night"].map((shift) => (
                      shift.doctor_id ? (
                        <DraggableBadge
                          key={shift.id} // Unique key for each badge
                          id={shift.id.toString()} // Unique ID for each shift assignment
                          name={shift.doctor_name}
                          bg="secondary"
                        />
                      ) : (
                        <span key={shift.id}>Unassigned</span>
                      )
                    ))}
                  </ShiftDroppable>
                ) : (
                  "No Night Shift"
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      );
    }

    return calendar;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <div>
        <div className="mb-3 d-flex align-items-center">
          <DatePicker
            selected={selectedMonth}
            onChange={(date) => setSelectedMonth(date)}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            className="form-control me-2"
          />
          <Button onClick={calculateSchedule}>Calculate Schedule</Button>
          <Button onClick={handleSaveSchedule} className="ms-2" variant="success">
            Save Schedule
          </Button>
        </div>
        <Row>{renderCalendar()}</Row>
      </div>
      <DragOverlay>
        {draggedDoctor ? (
          <Badge bg="info" pill className="doctor-badge">
            {draggedDoctor.name}
          </Badge>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default MonthlySchedule;