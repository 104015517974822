import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import LoginPage from "./components/login";
import HospitalScheduler from "./components/HospitalScheduler";
import ErrorBoundary from "./ErrorBoundary";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const API_URL = "https://docshift.onrender.com/api";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log("Found existing token:", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      // Call test endpoint to verify token
      axios.get(`${API_URL}/test`)
        .then(() => {
          console.log("Token verification successful");
          setIsAuthenticated(true);
        })
        .catch((error) => {
          console.error("Token verification failed:", error);
          localStorage.removeItem("token");
          delete axios.defaults.headers.common["Authorization"];
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleLogin = async (token) => {
    try {
      console.log("Setting token:", token);
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed");
    }
  };

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </Container>
    );
  }

  return (
    <ErrorBoundary>
      <Container fluid className="p-0">
        {!isAuthenticated ? (
          <LoginPage onLogin={handleLogin} />
        ) : (
          <HospitalScheduler />
        )}
        <ToastContainer />
      </Container>
    </ErrorBoundary>
  );
}

export default App;