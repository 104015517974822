// DraggableBadge.js
import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { Badge } from 'react-bootstrap';
import './DoctorBadge.css';

const DraggableBadge = ({ id, name, bg }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab',
    zIndex: 1000, // Ensures badge is on top
  };

  return (
    <Badge
      ref={setNodeRef}
      style={style}
      bg={bg}
      pill
      {...attributes}
      {...listeners}
      className={`me-1 mb-1 doctor-badge ${isDragging ? 'dragging' : ''}`}
    >
      {name}
    </Badge>
  );
};

export default DraggableBadge;