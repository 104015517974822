export const API_URL = "https://docshift.onrender.com/api";

export const ENDPOINTS = {
  LOGIN: "/login",
  DOCTORS: "/doctors",
  WISHES: "/wishes",
  SHIFTS: "/shifts",
  GENERATE_SCHEDULE: "/generate_schedule", // Add this line if it's missing
  HELLO: "/hello",
};
