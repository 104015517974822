// HospitalScheduler.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Tabs,
  Tab,
  Modal,
  Badge,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { API_URL, ENDPOINTS } from "./config";
import "react-datepicker/dist/react-datepicker.css";
import MonthlySchedule from "./MonthlySchedule";

// Sub-component
function DoctorManagement({ doctors, addDoctor, updateDoctor, deleteDoctor }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    addDoctor(data);
    reset();
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Doctor Name</Form.Label>
          <Form.Control
            {...register("name", { required: "Name is required" })}
          />
          {errors.name && (
            <Form.Text className="text-danger">{errors.name.message}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Years of Experience</Form.Label>
          <Form.Control
            type="number"
            {...register("yearsOfExperience", {
              required: "Years of experience is required",
              min: {
                value: 0,
                message: "Years of experience must be positive",
              },
            })}
          />
          {errors.yearsOfExperience && (
            <Form.Text className="text-danger">
              {errors.yearsOfExperience.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Skill Level (1-10)</Form.Label>
          <Controller
            name="skillLevel"
            control={control}
            rules={{ required: "Skill level is required", min: 1, max: 10 }}
            render={({ field }) => <Form.Range {...field} min={1} max={10} />}
          />
          {errors.skillLevel && (
            <Form.Text className="text-danger">
              {errors.skillLevel.message}
            </Form.Text>
          )}
        </Form.Group>
        <Button type="submit">Add Doctor</Button>
      </Form>
      <Row>
        {doctors.map((doctor) => (
          <Col key={doctor.id} md={4} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>{doctor.name}</Card.Title>
                <Card.Text>
                  Years of Experience: {doctor.yearsOfExperience}
                  <br />
                  Skill Level: {doctor.skillLevel}
                </Card.Text>
                <Button
                  onClick={() =>
                    updateDoctor({
                      ...doctor,
                      skillLevel: Math.min(doctor.skillLevel + 1, 10),
                    })
                  }
                  variant="primary"
                  className="me-2"
                >
                  Increase Skill
                </Button>
                <Button
                  onClick={() => deleteDoctor(doctor.id)}
                  variant="danger"
                >
                  Delete
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

function WishesManagement({ doctors, addWish }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    addWish(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label>Select Doctor</Form.Label>
        <Controller
          name="doctorId"
          control={control}
          rules={{ required: "Doctor selection is required" }}
          render={({ field }) => (
            <Form.Select {...field}>
              <option value="">Select a doctor</option>
              {doctors.map((doctor) => (
                <option key={doctor.id} value={doctor.id}>
                  {doctor.name}
                </option>
              ))}
            </Form.Select>
          )}
        />
        {errors.doctorId && (
          <Form.Text className="text-danger">
            {errors.doctorId.message}
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Select Date</Form.Label>
        <Controller
          name="date"
          control={control}
          rules={{ required: "Date is required" }}
          render={({ field }) => (
            <DatePicker
              selected={field.value}
              onChange={(date) => field.onChange(date)}
              className="form-control"
            />
          )}
        />
        {errors.date && (
          <Form.Text className="text-danger">{errors.date.message}</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Wish Type</Form.Label>
        <Controller
          name="type"
          control={control}
          rules={{ required: "Wish type is required" }}
          render={({ field }) => (
            <Form.Select {...field}>
              <option value="">Select wish type</option>
              <option value="weekend">Weekend Off</option>
              <option value="night">Night Shift</option>
            </Form.Select>
          )}
        />
        {errors.type && (
          <Form.Text className="text-danger">{errors.type.message}</Form.Text>
        )}
      </Form.Group>
      <Button type="submit">Add Wish</Button>
    </Form>
  );
}

function HospitalScheduler() {
  const [doctors, setDoctors] = useState([]);
  const [wishes, setWishes] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);

  // API calls
  const fetchDoctors = async () => {
    try {
      const response = await axios.get(`${API_URL}${ENDPOINTS.DOCTORS}`);
      setDoctors(response.data);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      toast.error("Failed to fetch doctors");
    }
  };

  const fetchWishes = async () => {
    try {
      const response = await axios.get(`${API_URL}${ENDPOINTS.WISHES}`);
      setWishes(response.data);
    } catch (error) {
      console.error("Error fetching wishes:", error);
      toast.error("Failed to fetch wishes");
    }
  };

  const fetchShifts = async () => {
    try {
      const response = await axios.get(`${API_URL}${ENDPOINTS.SHIFTS}`);
      setShifts(response.data);
    } catch (error) {
      console.error("Error fetching shifts:", error);
      toast.error("Failed to fetch shifts");
    }
  };

  // Add token setup and initial data fetch
  useEffect(() => {
    const fetchInitialData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      try {
        console.log("Fetching initial data...");
        await Promise.all([fetchDoctors(), fetchWishes(), fetchShifts()]);
        console.log("Initial data fetched successfully");
      } catch (error) {
        console.error("Error fetching initial data:", error);
        toast.error("Failed to fetch initial data");
      }
    };

    const token = localStorage.getItem("token");
    if (token) {
      console.log("Setting token in HospitalScheduler");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    fetchInitialData();
  }, [selectedMonth]);

  const deleteDoctor = (doctorId) => {
    axios
      .delete(`${API_URL}${ENDPOINTS.DOCTORS}/${doctorId}`)
      .then(() => {
        setDoctors(doctors.filter((doctor) => doctor.id !== doctorId));
        toast.success("Doctor deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting doctor:", error);
        toast.error("Failed to delete doctor");
      });
  };

  const addDoctor = (doctor) => {
    axios
      .post(`${API_URL}${ENDPOINTS.DOCTORS}`, doctor)
      .then((response) => {
        setDoctors([...doctors, response.data]);
        toast.success(`Doctor ${response.data.name} added successfully`);
      })
      .catch((error) => {
        console.error("Error adding doctor:", error);
        toast.error("Failed to add doctor");
      });
  };

  const updateDoctor = (updatedDoctor) => {
    axios
      .put(`${API_URL}${ENDPOINTS.DOCTORS}/${updatedDoctor.id}`, updatedDoctor)
      .then(() => {
        setDoctors(
          doctors.map((doctor) =>
            doctor.id === updatedDoctor.id ? updatedDoctor : doctor
          )
        );
        toast.success(`Doctor ${updatedDoctor.name}'s information updated`);
      })
      .catch((error) => {
        console.error("Error updating doctor:", error);
        toast.error("Failed to update doctor");
      });
  };

  const addWish = (wish) => {
    axios
      .post(`${API_URL}${ENDPOINTS.WISHES}`, wish)
      .then((response) => {
        setWishes([...wishes, response.data]);
        toast.success("Wish added successfully");
      })
      .catch((error) => {
        console.error("Error adding wish:", error);
        toast.error("Failed to add wish");
      });
  };

  const calculateSchedule = () => {
    axios
      .post(`${API_URL}${ENDPOINTS.GENERATE_SCHEDULE}`, {
        month: {
          year: selectedMonth.getFullYear(),
          month: selectedMonth.getMonth(),
        },
      })
      .then((response) => {
        fetchShifts();
        toast.success("Schedule calculated successfully");
      })
      .catch((error) => {
        console.error("Error calculating schedule:", error);
        const errorMessage =
          error.response?.data?.message || "Failed to calculate schedule";
        toast.error(errorMessage);
      });
  };

  return (
    <Container className="mt-4">
      <h1 className="text-center mb-4">Hospital Scheduler</h1>
      <Tabs defaultActiveKey="doctors" className="mb-3">
        <Tab eventKey="doctors" title="Manage Doctors">
          <DoctorManagement
            doctors={doctors}
            addDoctor={addDoctor}
            updateDoctor={updateDoctor}
            deleteDoctor={deleteDoctor}
          />
        </Tab>
        <Tab eventKey="wishes" title="Doctor Wishes">
          <WishesManagement doctors={doctors} addWish={addWish} />
        </Tab>
        <Tab eventKey="schedule" title="Monthly Schedule">
          <MonthlySchedule
            doctors={doctors}
            shifts={shifts}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            calculateSchedule={calculateSchedule}
            setSelectedDate={setSelectedDate}
            setShifts={setShifts}
          />
        </Tab>
      </Tabs>
      <Modal show={!!selectedDate} onHide={() => setSelectedDate(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedDate?.toDateString()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Assigned Doctor</h5>
          <p>
            {shifts
              .filter(
                (s) =>
                  s.date === selectedDate?.toISOString().split("T")[0] &&
                  (s.shift_type === "day" || s.shift_type === "night")
              )
              .map((s) => (
                <Badge
                  key={`${s.id}-${s.shift_type}`}
                  bg={s.shift_type === "day" ? "primary" : "secondary"}
                  pill
                  className="me-1 mb-1"
                >
                  {s.doctor_name}
                </Badge>
              )) || "Unassigned"}
          </p>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

// Export the main component
export default HospitalScheduler;
