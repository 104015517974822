// ShiftDroppable.js
import React from "react";
import { useDroppable } from "@dnd-kit/core";

const ShiftDroppable = ({ shiftId, children }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: shiftId.toString(),
  });

  const style = {
    backgroundColor: isOver ? "#e0f7fa" : "transparent",
    minHeight: "30px",
    padding: "4px",
    borderRadius: "4px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

export default ShiftDroppable;
